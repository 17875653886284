<template>
<!-- 中间 -->
    <div style="position:relative;">
		<div class="container">
			<div class="contentItem lightBack" v-for="(item,index) in list" :key="index">
				<img class="img" :src="item.img" >
				<div>
					<div class="lightGrayFont" style="font-size:0.12rem;">{{item.title}}</div>
					<countTo :startVal='item.preNum' :endVal='item.num' :duration='3000' class="whiteFont" style="font-size:0.24rem;">{{item.num}}</countTo>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import bus from '/src/assets/js/bus.js'

import countTo from 'vue-count-to';
	export default {
		components: { countTo },
		data() {
		    return { 
		        list:[
					{ 
						title:'在线运动人数' , 
						num:0, 
						img:require('../../../assets/images/running.png'),
						preNum:0 
					},
					{ 
						title:'今日运动人数' , 
						num:0, 
						img:require('../../../assets/images/today.png') ,
						preNum:0 
					},
					{ 
						title:'运动人数' , 
						num:0, 
						img:require('../../../assets/images/books.png'),
						preNum:0 
					},
					{ 
						title:'运动时长(时)' , 
						num:0, 
						img:require('../../../assets/images/people.png') ,
						preNum:0 
					},
 
				],
				timer:""
		    }
		},
		methods: {
			getData(){
				let that = this;
				let data = {
					street:window.clickstreet
				}
				let url = window.appHost+"/api/v1/screen/App_vital/vital?street="+window.clickstreet;
				let result = window.ajaxRequest(url,JSON.stringify(data),'post')
				// console.log(result)
				that.list[0].preNum = that.list[0].num;
				that.list[1].preNum = that.list[1].num;
				that.list[2].preNum = that.list[2].num;
				that.list[3].preNum = that.list[3].num;
				that.list[0].num = result.msg.onlineNum;
				that.list[1].num = result.msg.day_sportsNum;
				that.list[2].num = result.msg.all_sportsNum;
				that.list[3].num = result.msg.timeNum;
			}
		},
		mounted () {
			this.getData();
			bus.$on('now_street',res=>{
				// console.log(res)
				this.getData();
			})
			this.timer = setInterval(() => {
				this.getData();
			}, 300000);
		},
		beforeDestroy(){
			bus.$off('now_street')
			clearInterval(this.timer)
		}
	}
</script>

<style scoped>
	.container{
		width: 12.14rem;
		height: 1.00rem;
		display: flex;
		justify-content: space-between;
		/* align-items: center; */
	}
	.contentItem{
		width: 2.84rem;
		height: 1.00rem;
		display: flex;
		/* justify-content: center; */
        /* justify-content: space-around; */
        padding:0rem 0.1rem;
        box-sizing: border-box;
		align-items: center;
	}
    .contentItem>img{
        margin:0rem 0.2rem
    }
	.img{
		width: 0.33rem;
		height: 0.34rem;
		margin-right: 0.1rem;
	}
</style>